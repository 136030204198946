"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackDecoder = exports.TrackEncoder = exports.isSamePoint = exports.TrackEncoderOptions = void 0;
var currentDataVersion = 2;
var TrackEncoderOptions = /** @class */ (function () {
    function TrackEncoderOptions() {
        this.optg = true;
        this.sprec = 1000;
        this.aprec = 100;
        this.lprec = 100;
        this.gprec = 100;
        this.tprec = 1;
        this.llprec = 10000000;
    }
    return TrackEncoderOptions;
}());
exports.TrackEncoderOptions = TrackEncoderOptions;
function isSamePoint(p1, p2) {
    return (p1.t === p2.t &&
        p1.a === p2.a &&
        p1.s === p2.s &&
        p1.l === p2.l &&
        p1.g === p2.g &&
        p1.x === p2.x &&
        p1.y === p2.y);
}
exports.isSamePoint = isSamePoint;
var TrackHeader = /** @class */ (function (_super) {
    __extends(TrackHeader, _super);
    function TrackHeader() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tv = currentDataVersion; // version number
        _this.count = 0;
        _this.firstv = { t: 0, a: 0, s: 0, l: 0, g: 0, x: 0, y: 0 };
        _this.lastv = { t: 0, a: 0, s: 0, l: 0, g: 0, x: 0, y: 0 };
        _this.lastflags = 0;
        return _this;
    }
    return TrackHeader;
}(TrackEncoderOptions));
var pointFieldsOrdered = ["l", "a", "s", "t", "g", "x", "y"];
var TrackEncoder = /** @class */ (function () {
    function TrackEncoder(options) {
        this.newdata = [];
        this.prebuffer = null;
        this.header = Object.assign(new TrackHeader(), options, {
            tv: currentDataVersion,
        });
    }
    TrackEncoder.createAppender = function (buf) { };
    TrackEncoder.prototype.encodedBuffer = function () {
        return Buffer.concat([
            Buffer.from(JSON.stringify(this.header)),
            Buffer.from([0]),
            Buffer.from(this.prebuffer ? this.prebuffer : []),
            Buffer.from(this.newdata),
        ]);
    };
    TrackEncoder.prototype.addPoint = function (point) {
        return this.addPoints([point]);
    };
    TrackEncoder.prototype.addPoints = function (points) {
        var _this = this;
        var start = this.newdata.length;
        points.forEach(function (pd) {
            var _a;
            if (_this.header.count == 0) {
                pointFieldsOrdered.forEach(function (ks, idx) {
                    var k = ks;
                    if (ks === "x" || ks === "y")
                        ks = "ll";
                    var prec = _this.header[(ks + "prec")];
                    if (k === "t")
                        _this.header.firstv[k] = pd[k] - 1000;
                    else
                        _this.header.firstv[k] = Math.floor(pd[k] * prec);
                    _this.header.lastv[k] = _this.header.firstv[k];
                });
            }
            _this.header.count++;
            var newentry = [];
            var newflags = 0;
            /* Väljade järjekord on väiksemini muutuvad eespool */
            pointFieldsOrdered.forEach(function (ks, idx) {
                var k = ks;
                if (ks === "x" || ks === "y")
                    ks = "ll";
                var prec = _this.header[(ks + "prec")];
                var sv = k === "t"
                    ? pd[k] - _this.header.lastv[k] - 1000
                    : Math.floor(pd[k] * prec) - _this.header.lastv[k];
                if (_this.header.optg && k === "g" && pd.s < 3)
                    return;
                var iv = Math.floor(sv);
                if (k === "t")
                    _this.header.lastv[k] = pd[k];
                else
                    _this.header.lastv[k] += iv;
                if (iv === 0)
                    return;
                newflags |= 1 << idx;
                //iv = iv << 1;
                iv = iv * 2;
                if (sv < 0)
                    iv = ~iv;
                while (iv > 0) {
                    newentry.push((iv & 0x7f) | (iv > 0x7f ? 0x80 : 0));
                    //iv = iv >> 7;
                    iv = Math.floor(iv / 128);
                }
            });
            if (newentry[0] & 0x80 ||
                (newflags | 0x80) !== _this.header.lastflags ||
                newflags == 0)
                newflags |= 0x80;
            if (newflags & 0x80)
                _this.newdata.push(newflags);
            (_a = _this.newdata).push.apply(_a, newentry);
            if (newflags & 0x80)
                _this.header.lastflags = newflags;
        });
        return this.newdata.slice(start);
    };
    TrackEncoder.prototype.addFirePoint = function (t, fp) {
        fp.x = fp.p._latitude;
        fp.y = fp.p._longitude;
        fp.t = parseInt(t);
        return this.addPoint(fp);
    };
    TrackEncoder.fromEncode = function (buf) {
        var idx = 0;
        var ridx = buf.indexOf(0, 0);
        if (ridx === -1)
            return null;
        if (buf.indexOf(0, ridx + 1) !== -1) {
            console.log("Can extend only on track buffer. Found multiple tracks");
            return null;
        }
        var header = JSON.parse(Buffer.from(buf.slice(idx, ridx)).toString("utf-8"));
        if (header.tv === undefined) {
            console.log("Not encoded track. Cant exteend");
            return null;
        }
        if (header.tv > currentDataVersion) {
            console.log("track data version in buffer is higher than currently know. Overriding it to currently known version");
        }
        var resp = new TrackEncoder(header);
        resp.prebuffer = buf.slice(ridx + 1);
        return resp;
    };
    TrackEncoder.fromFireDoc = function (data) {
        var resp = Buffer.from([]);
        Object.entries(data).forEach(function (_a) {
            var tid = _a[0], tdata = _a[1];
            Object.entries(tdata).forEach(function (_a) {
                var devid = _a[0], devdata = _a[1];
                var enc = new TrackEncoder({ devid: devid, tid: tid });
                Object.entries(devdata).forEach(function (_a) {
                    var t = _a[0], pd = _a[1];
                    enc.addFirePoint(t, pd);
                });
                resp = Buffer.concat([resp, enc.encodedBuffer()]);
            });
        });
        return resp;
    };
    return TrackEncoder;
}());
exports.TrackEncoder = TrackEncoder;
var TrackDecoder = /** @class */ (function () {
    function TrackDecoder(header) {
        this.flags = 0;
        this.header = header;
        this.point = header.firstv;
    }
    TrackDecoder.prototype.processBuffer = function (buf, count) {
        var _this = this;
        if (count === void 0) { count = null; }
        var points = [];
        var idx = 0;
        var _loop_1 = function () {
            if (buf[idx] & 0x80)
                this_1.flags = buf[idx++];
            var addpoint = Object.assign({}, this_1.point);
            pointFieldsOrdered.forEach(function (ks, fidx) {
                var k = ks;
                if (_this.flags & (1 << fidx)) {
                    var v = 0;
                    var pos = 0;
                    do {
                        //v |= (buf[idx] & 0x7f) << (7 * pos++);
                        v += (buf[idx] & 0x7f) * Math.pow(2, 7 * pos++);
                    } while (buf[idx++] & 0x80);
                    if (v & 0x1)
                        v = ~v;
                    //v = v >> 1;
                    v = Math.floor(v / 2);
                    if (k === "t")
                        v = v + 1000;
                    _this.point[k] += v;
                }
                else {
                    if (k == "t")
                        _this.point.t += 1000;
                }
                if (ks === "x" || ks === "y")
                    ks = "ll";
                var prec = _this.header[(ks + "prec")];
                addpoint[k] = _this.point[k] / prec;
            });
            points.push(Object.assign({}, addpoint));
            if (count !== null)
                count--;
        };
        var this_1 = this;
        while ((count != null && count > 0) ||
            (count === null && idx < buf.length)) {
            _loop_1();
        }
        return { points: points, readcount: idx };
    };
    TrackDecoder.startStreamDecode = function (buf) {
        var idx = buf.indexOf(0, 0);
        if (idx === -1) {
            console.log("Cant find header");
            return {};
        }
        if (buf.indexOf(0, idx + 1) !== -1) {
            console.log("Streamdecode can work on on track only buffer");
            return {};
        }
        var header = JSON.parse(Buffer.from(buf.slice(0, idx)).toString("utf-8"));
        var decoder = new TrackDecoder(header);
        var points = decoder.processBuffer(buf.subarray(idx + 1)).points;
        return { decoder: decoder, points: points };
    };
    TrackDecoder.decode = function (buf) {
        var _a;
        var ridx = 0;
        var idx = 0;
        var resp = {};
        while ((ridx = buf.indexOf(0, idx)) !== -1) {
            var header = JSON.parse(Buffer.from(buf.slice(idx, ridx++)).toString("utf-8"));
            if (header.tv === undefined) {
                console.log("Not encoded track");
                return resp;
            }
            if (header.tv > 2) {
                console.log("Might not be able to decode correctly. Known max version is 1, but encoded data is of version", header.tv);
            }
            if (header.tid && header.devid) {
                var decoder = new TrackDecoder(header);
                var _b = decoder.processBuffer(buf.subarray(ridx), header.count), points = _b.points, readcount = _b.readcount;
                resp[header.tid] = (_a = {}, _a[header.devid] = points, _a);
                idx = ridx + readcount;
            }
            else {
                idx = ridx + 1;
            }
        }
        return resp;
    };
    return TrackDecoder;
}());
exports.TrackDecoder = TrackDecoder;
